import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import { BrowserRouter } from 'react-router-dom'
import PageRoot from './pages/Root'

// Global state manager
import { StateProvider, reducer, initialState } from './hooks/useGlobal'

import {
  AlertProvider,
  // MeProvider,
  // LaboratoryProvider,
  // ClientProvider,
} from './hooks'

// Apollo
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'

import PageLoading from './pages/Loading'

import config from './config'

// ----------------------------------------------------------------------------

const { APP, ENV } = config

const cache = new InMemoryCache()
const link = createUploadLink({
  uri: ['development'].includes(ENV)
    ? `http://192.168.77.11:${APP.BACK.PORT}/graphql`
    : `${window.location.protocol}//${window.location.hostname.replace(
        /^.*?\./,
        'api.',
      )}:${APP.BACK.PORT}/graphql`,
  credentials: 'include',
})

const client = new ApolloClient({
  cache,
  link,
})

ReactDOM.render(
  <>
    {['development', 'testing'].includes(ENV) && (
      <div
        style={{
          width: '100%',
          backgroundColor: 'orange',
          color: 'white',
          padding: '5px',
          textAlign: 'center',
        }}
      >
        {ENV} enviroment
      </div>
    )}
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AlertProvider>
          <StateProvider initialState={initialState} reducer={reducer}>
            <PageRoot />
          </StateProvider>
        </AlertProvider>
      </BrowserRouter>
    </ApolloProvider>
  </>,
  document.getElementById('root'),
)
